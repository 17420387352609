export default [
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/profile/index2.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/profile/detail',
        name: 'profile-detail',
        component: () => import('@/views/profile/detail.vue'),
         meta: {
            layout: 'full',
        },
    },
    {
        path: '/profile/company',
        name: 'profile-company',
        component: () => import('@/views/profile/company.vue'),
         meta: {
            layout: 'full',
        },
    },

    {
        path: '/profile/documents',
        name: 'profile-documents',
        component: () => import('@/views/profile/documents.vue'),
         meta: {
            layout: 'full',
        },
    },
]