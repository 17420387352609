export default [
    {
        path: '/sales/quotations',
        name: 'list-quotations',
        component: () => import('@/views/request/quotations/index2.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/sales/quotation/:id',
        name: 'detail-quotation',
        component: () => import('@/views/request/quotations/detail2.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/sales/quotation/add/:id',
        name: 'add-quotation',
        component: () => import('@/views/request/quotations/add.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/sales/quotation/viewpdf/:id',
        name: 'view-quotation-pdf',
        component: () => import('@/views/request/quotations/viewpdf.vue'),
        meta: {
            layout: 'full',
        },
    },
]