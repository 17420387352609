export default [
    {
        path: '/settings/terms',
        name: 'terms',
        props: true,
        component: () => import('@/views/terms/index.vue'),
        meta: {
            pageTitle: 'Pengaturan',
            breadcrumb: [
                {
                text: 'Syarat & Ketentuan',
                active: true,
                },
            ],
        },
    },
]