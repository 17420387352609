export default [
    {
        path: '/contracts',
        name: 'list-contracts',
        component: () => import('@/views/contract/index2.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/contract/add',
        name: 'create-contract',
        component: () => import('@/views/contract/create.vue'),
        meta: {
            pageTitle: 'Buat Kontrak Baru',
            breadcrumb: [
                {
                text: 'Kontrak',
                to: { name: 'contracts' },
                },
                {
                text: 'Buat Kontrak Baru',
                active: true,
                },
            ],
        },
    },
    {
        path: '/contract/:id/:flagreturn',
        name: 'detail-contract',
        component: () => import('@/views/contract/detail2.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/contractget/viewpdf/:id',
        name: 'view-contract-pdf',
        component: () => import('@/views/contract/viewpdf.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/contractget/uploadpayment/:id',
        name: 'upload-contract-payment',
        component: () => import('@/views/contract/uploadpayment.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/contractset/uploadreceiveunit/:id',
        name: 'upload-contract-unit-receive',
        component: () => import('@/views/contract/uploadreceive.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/contractput/scanqr',
        name: 'scanqr-contract',
        component: () => import('@/views/contract/scanqr.vue'),
        meta: {
            layout: 'full',
        },
    },
]