export default [
    {
        path: '/information',
        name: 'information',
        component: () => import('@/views/information/index.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/information/viewpdf',
        name: 'informationview',
        component: () => import('@/views/information/viewpdf.vue'),
        meta: {
            layout: 'full',
        },
    },
]