export default [
    {
        path: '/emergency/loc/:lat',
        name: 'user-loc-emergency',
        component: () => import('@/views/emergencies/emergencyloc.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/emergency/add',
        name: 'user-add-emergency',
        component: () => import('@/views/emergencies/add.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/emergency/detail/:id',
        name: 'detail-emergency',
        component: () => import('@/views/emergencies/detail.vue'),
         meta: {
            layout: 'full',
        },
    },
]