export default [
    {
        path: '/request/part-replacements',
        name: 'request-part-replacements',
        props: true,
        component: () => import('@/views/request/part-replacements/index.vue'),
        meta: {
            pageTitle: 'Penggantian Part',
            breadcrumb: [
                {
                text: 'Penggantian Part',
                active: true,
                },
            ],
        },
    },
    {
        path: '/request/part-replacement/:id',
        name: 'detail-part-replacement',
        component: () => import('@/views/request/part-replacements/detail.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/request/part-replacement/add/:id/:unitid/:policeno',
        name: 'add-part-replacement',
        component: () => import('@/views/request/part-replacements/add.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/request/part-replacement/workshop/:idworkshop/:idrequest',
        name: 'bengkel-part-replacement',
        component: () => import('@/views/request/part-replacements/detailbengkel.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/request/part-replacement/viewpdf/:id',
        name: 'view-spk-part-pdf',
        component: () => import('@/views/request/part-replacements/viewpdf.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/request/part-replacement/edit/:id',
        name: 'edit-part-replacement',
        component: () => import('@/views/request/part-replacements/edit.vue'),
        meta: {
            layout: 'full',
        },
    },
]