export default [
    {
        path: '/request/services',
        name: 'request-services',
        props: true,
        component: () => import('@/views/request/services/index.vue'),
        meta: {
            pageTitle: 'Permintaan Service',
            breadcrumb: [
                {
                text: 'Permintaan Service',
                active: true,
                },
            ],
        },
    },
    {
        path: '/request/service/:id',
        name: 'detail-service',
        component: () => import('@/views/request/services/detail.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/request/service/add/:id/:unitid/:policeno',
        name: 'add-service',
        component: () => import('@/views/request/services/add.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/request/service/viewpdf/:id',
        name: 'view-spk-service-pdf',
        component: () => import('@/views/request/services/viewpdf.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/request/service/workshop/:idworkshop/:idrequest',
        name: 'bengkel-servis',
        component: () => import('@/views/request/services/detailbengkel.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/request/service/edit/:id',
        name: 'edit-service',
        component: () => import('@/views/request/services/edit.vue'),
        meta: {
            layout: 'full',
        },
    },
]