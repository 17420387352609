export default [
    {
        path: '/catalogs',
        name: 'catalogs',
        component: () => import('@/views/catalog/index2.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/catalog/add',
        name: 'create-catalog',
        component: () => import('@/views/catalog/create.vue'),
        meta: {
            pageTitle: 'Buat Catalog Baru',
            breadcrumb: [
                {
                text: 'Katalog',
                to: { name: 'catalogs' },
                },
                {
                text: 'Buat Catalog Baru',
                active: true,
                },
            ],
        },
    },
    {
        path: '/catalog/:id',
        name: 'detail-catalog',
        component: () => import('@/views/catalog/detail2.vue'),
        meta: {
            layout: 'full',
        },
    },
]