export default [
    {
        path: '/place/charging-stations',
        name: 'place-charging-stations',
        component: () => import('@/views/place/charging-station/index2.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/place/charging-station/add',
        name: 'create-charging-station',
        component: () => import('@/views/place/charging-station/create.vue'),
        meta: {
            pageTitle: 'Buat SPKLU Baru',
            breadcrumb: [
                {
                text: 'SPKLU',
                to: { name: 'place-charging-stations' },
                },
                {
                text: 'Buat SPKLU Baru',
                active: true,
                },
            ],
        },
    },
    {
        path: '/place/charging-station/:id',
        name: 'detail-charging-station',
        component: () => import('@/views/place/charging-station/detail2.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/place/charging-stationlist/:id',
        name: 'detail-charging-station-list',
        component: () => import('@/views/place/charging-station/detaillist.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/place/list-charging-station',
        name: 'list-charging-station',
        component: () => import('@/views/place/charging-station/listspklu.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/place/workshops',
        name: 'place-workshops',
        component: () => import('@/views/place/workshop/index2.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/place/list-workstation',
        name: 'list-workstation',
        component: () => import('@/views/place/workshop/listworkshop.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/place/workshop/add',
        name: 'create-workshop',
        component: () => import('@/views/place/workshop/create.vue'),
        meta: {
            pageTitle: 'Buat Bengkel Baru',
            breadcrumb: [
                {
                text: 'Bengkel',
                to: { name: 'place-workshops' },
                },
                {
                text: 'Buat Bengkel Baru',
                active: true,
                },
            ],
        },
    },
    {
        path: '/place/workshop/:id',
        name: 'detail-workshop',
        component: () => import('@/views/place/workshop/detail2.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/place/workshoplist/:id',
        name: 'detail-workshop-list',
        component: () => import('@/views/place/workshop/detaillist.vue'),
        meta: {
            layout: 'full',
        },
    },
]