export default [
    {
        path: '/units',
        name: 'units',
        component: () => import('@/views/unit/units/index2.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/unit/add',
        name: 'create-unit',
        component: () => import('@/views/unit/units/create.vue'),
        meta: {
            pageTitle: 'Buat Unit Baru',
            breadcrumb: [
                {
                text: 'Unit',
                to: { name: 'units' },
                },
                {
                text: 'Buat Unit Baru',
                active: true,
                },
            ],
        },
    },
    {
        path: '/unit/:id',
        name: 'detail-unit',
        component: () => import('@/views/unit/units/detail2.vue'),
        meta: {
            layout: 'full',
        },
    },

    
    {
        path: '/unit-categories',
        name: 'unit-categories',
        props: true,
        component: () => import('@/views/unit/categories/index.vue'),
        meta: {
            pageTitle: 'Kategori Unit',
            breadcrumb: [
                {
                text: 'Kategori Unit',
                active: true,
                },
            ],
        },
    },
    {
        path: '/unit-category/add',
        name: 'create-unit-category',
        component: () => import('@/views/unit/categories/create.vue'),
        meta: {
            pageTitle: 'Buat Kategori Baru',
            breadcrumb: [
                {
                text: 'Kategori Unit',
                to: { name: 'unit-categories' },
                },
                {
                text: 'Buat Kategori Baru',
                active: true,
                },
            ],
        },
    },
    {
        path: '/unit-category/:id',
        name: 'detail-unit-category',
        props: true,
        component: () => import('@/views/unit/categories/detail.vue'),
        meta: {
            pageTitle: 'Kategori Unit',
            breadcrumb: [
                {
                text: 'Kategori Unit',
                to: { name: 'unit-categories' },
                },
                {
                text: 'Kategori',
                active: true,
                },
            ],
        },
    },
]