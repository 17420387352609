export default [
    {
        path: '/request/towings',
        name: 'request-towings',
        props: true,
        component: () => import('@/views/request/towings/index.vue'),
        meta: {
            pageTitle: 'Permintaan Towing',
            breadcrumb: [
                {
                text: 'Permintaan Towing',
                active: true,
                },
            ],
        },
    },
    {
        path: '/request/towing/:id',
        name: 'detail-towing',
        component: () => import('@/views/request/towings/detail.vue'),
         meta: {
            layout: 'full',
        },
    },

    {
        path: '/request/towing/loc/:id/:unitid/:policeno/:lat',
        name: 'user-loc-towing',
        component: () => import('@/views/request/towings/towingloc.vue'),
        meta: {
            layout: 'full',
        },
    },

    {
        path: '/request/towing/add/:id/:unitid/:policeno/',
        name: 'add-request-towing',
        component: () => import('@/views/request/towings/add.vue'),
        meta: {
            layout: 'full',
        },
    },
]