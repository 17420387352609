import Vue from 'vue'
import OneSignal from "onesignal-vue";
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import * as VueGoogleMaps from 'vue2-google-maps'

//Flicking
import Flicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";

//FAB
import VueFab from "vue-float-action-button";


import VueBridgeWebview from 'vue-bridge-webview'
Vue.use(VueBridgeWebview)

// set default config 
VueBridgeWebview.config(0,true);


import VueZxingScanner from "vue-zxing-scanner";
Vue.use(VueZxingScanner);


//Flicking
Vue.use(Flicking);

//FAB
Vue.use(VueFab);


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAzlonStW7-uP_Km6UVUuuVxjYKPNpdYNM',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  installComponents: true,
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false;

Vue.use(OneSignal);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
