export default [
    {
        path: '/settings/reminder',
        name: 'reminder',
        props: true,
        component: () => import('@/views/reminder/due-date/index.vue'),
        meta: {
            pageTitle: 'Pengaturan',
            breadcrumb: [
                {
                text: 'Pengaturan Reminder',
                active: true,
                },
            ],
        },
    },
]